<template>
  <div>
    <div v-if="routes.length!==0" class="openPop" @click="handleLineShow"><span>{{ routes.length }}</span><p>推荐线路</p></div>

    <div v-if="showPopup" id="floatball" class="floatball" :style="{height:(position.h)+'px'}">
      <div class="pop-title"><p @click="showPopupClose"><img src="/static/images/map/info03.png"></p></div>
      <div
        id="moveable"
        class="moveable"
        :style="{height:position.h+'px'}"
        @touchstart="down"
        @touchmove="move"
        @touchend="end"
      >
        <div id="showHeight" class="showHeight">
          <div class="list">
            <div v-for="(item,index) in columnTwo" :key="item.id">
              <div v-if="item.name==='红色游景区'" class="list-title select-red" @click="handleColumnTwo(index,item.id)">
                <img src="/static/images/start.png">
                {{ item.name }}
              </div>
              <div v-else-if="item.name==='冰雪游景区'" :class="(index===activeTwo?'select':'')" class="list-title" @click="handleColumnTwo(index,item.id)">
                <img src="/static/images/ice.png">
                {{ item.name }}
              </div>
              <div v-else-if="item.name==='无障碍游景区'" :class="(index===activeTwo?'select':'')" class="list-title" @click="handleColumnTwo(index,item.id)">
                <img src="/static/images/disabled.png">
                {{ item.name }}
              </div>
              <div v-else :class="(index===activeTwo?'select':'')" class="list-title" @click="handleColumnTwo(index,item.id)">
                {{ item.name }}
              </div>
            </div>
          </div>
          <div v-if="columnThree" class="list-three">
            <van-divider>
              <span v-if="threeName==='红色游景区'" class="red">{{ threeName }}</span>
              <span v-else>{{ threeName }}</span>
            </van-divider>
            <div v-for="(item,index) in columnThree" :key="item.id">
              <div v-if="threeName==='红色游景区'" :class="(index===activeThree?'select-red':'')" class="list-title" @click="handleColumnThree(index,item.id)">{{ item.name }}</div>
              <div v-else :class="(index===activeThree?'select':'')" class="list-title" @click="handleColumnThree(index,item.id)">{{ item.name }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import eventBus from '@/utils/eventBus'
import { Divider } from 'vant'

export default {
  name: 'Moveable',
  components: {
    [Divider.name]: Divider
  },
  props: {
    routes: { type: Array, default: () => [] }
  },
  data() {
    return {
      flags: false,
      position: {
        x: 0,
        y: 0,
        h: 190
      },
      startY: '',
      moveY: '',
      lastY: '',
      showHeight: '',
      showPopup: true,
      columns: [],
      columnTwo: [],
      columnThree: [],
      threeName: '',
      activeTwo: 0,
      activeThree: 0
    }
  },
  created() {
    eventBus.$on('columns', (columns) => {
      this.columns = columns
      this.getAllColumns()
    })
    eventBus.$on('columnId', (id) => {
      this.getColumnTwo(id)
      this.showPopup = true
      id = ''
    })
  },
  methods: {
    handleLineShow() {
      this.showPopup = false
      eventBus.$emit('lineShow')
    },
    async getAllColumns() {
      this.columnTwo = this.columns[0].children
      if (this.columnTwo[0].children) {
        this.columnThree = this.columnTwo[0].children
        this.threeName = this.columnTwo[0].name
      }
    },
    getColumnTwo(id) {
      this.columns.forEach(column => {
        if (id === column.id) {
          this.columnTwo = column.children
          if (this.columnTwo[0].children) {
            this.columnThree = this.columnTwo[0].children
            this.threeName = this.columnTwo[0].name
            if (this.columnThree) {
              this.position.h = 250
            }
          } else {
            this.position.h = 190
            this.columnThree = ''
          }
        }
      })
      this.activeTwo = 0
    },
    handleColumnTwo(index, id) {
      this.activeTwo = index
      this.columnTwo.forEach(columnTwo => {
        if (id === columnTwo.id) {
          if (columnTwo.children) {
            this.position.h = 250
            this.columnThree = columnTwo.children
            this.threeName = columnTwo.name
            this.$emit('changeColumnId', this.columnThree[0].id)
          } else {
            this.position.h = 190
            this.columnThree = ''
            this.threeName = ''
            this.$emit('changeColumnId', columnTwo.id)
          }
        }
      })
      this.activeThree = 0
    },
    handleColumnThree(index, id) {
      this.activeThree = index
      this.$emit('changeColumnId', id)
    },
    down(event) {
      this.showHeight = document.getElementById('showHeight').clientHeight
      this.flags = true
      let touch
      if (event.touches) {
        touch = event.touches[0]
      } else {
        touch = event
      }
      this.startY = touch.clientY
    },
    move(event) {
      if (this.flags) {
        let touch
        if (event.touches) {
          touch = event.touches[0]
        } else {
          touch = event
        }
        // 定位滑块的位置
        if (!this.lastY) {
          this.moveY = this.startY - touch.clientY
        } else {
          this.moveY = this.lastY - touch.clientY
        }
        if (this.position.h > (this.showHeight + 100)) {
          this.position.h = this.showHeight + 90
        }
        this.position.h = this.position.h + this.moveY
        if (this.position.h < 190) {
          this.position.h = 190
        }
        this.lastY = touch.clientY
        // document.addEventListener('touchmove', function() {
        //   event.preventDefault()
        // }, false)
      }
    },
    end(event) {
      // const floatballHeight = document.getElementById('floatball').offsetTop
      // console.log(floatballHeight)
      // if (floatballHeight < 280 && floatballHeight > 0) {
      //   this.flags = false
      // } else if (floatballHeight > 280 && floatballHeight < 460) {
      //   this.flags = false
      // } else {
      //   this.flags = false
      // }
      this.flags = false
      this.lastY = ''
    },
    showPopupClose() {
      if (this.position.h > 600) {
        this.position.h = 190
      } else {
        this.showPopup = false
      }
    }
  }

}
</script>

<style lang="less" scoped>
@import '~@/styles/public.less';
.openPop {
  width: 90px;
  height: 36px;
  display: block;
  position: fixed;
  background: #fff;
  border-radius: 36px;
  box-shadow: 4px 4px 4px rgba(10, 36, 99, 0.1);
  left: 10px;
  bottom: 210px;
  z-index: 980;
  p{
    font-size: 14px;
    text-align: center;
    color: #333;
    line-height: 36px;
  }
  span{
    width: 20px;
    height: 20px;
    display: block;
    position: absolute;
    right: -5px;
    top: -5px;
    color: #fff;
    background: #ED1526;
    border-radius: 20px;
    line-height: 20px;
    font-size: 12px;
    text-align:center;
  }
}
.floatball{
  width: 100%;
  max-height: 680px;
  z-index: 999;
  position: fixed;
  bottom: 0;
  left: 0;
  overflow: hidden;
  background-color: #fff;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  box-shadow: 0px -5px 10px rgba(194, 194, 194, 0.5);
}
.moveable{
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  padding-top: 30px;
  box-sizing: border-box;

}
.showHeight{
  .autoHeight();
  .list{
    .autoHeight();
    padding:5px 10px 0;
    .list-title{
      font-size: 14px;
      min-width: 30%;
      background: #f1f1f1;
      float: left;
      padding: 0 4%;
      margin-left: 2.5%;
      margin-bottom: 3%;
      text-align: center;
      color: #666;
      line-height: 36px;
      border-radius: 20px;
      position: relative;
      img{
        position: absolute;
        left: -10px;
        top: -0;
        height: 30px;
        width: auto
      }
    }
    .select{
      background: #3165ec;
      color: #fff;
    }
    .select-red{
      background: #c33;
      color: #f8ec7c;
    }
  }
  .list-three{
    .autoHeight();
    padding:0px 10px 5px;
    .list-title{
      font-size: 14px;
      min-width: 30%;
      background: #f1f1f1;
      float: left;
      padding: 0 4%;
      margin-left: 2.5%;
      margin-bottom: 3%;
      text-align: center;
      color: #666;
      line-height: 36px;
      border-radius: 20px;
    }
    .red{ color: #c33;}
    .select{
      background: #6f85ff;
      color: #fff;
    }
    .select-red{
      background: #c33;
      color: #f8ec7c;
    }
  }
}
.pop-title {
  width: 10%;
  height: 24px;
  padding: 6px 0;
  position: absolute;
  top: 0;
  left: 45%;
  background-color: #fff;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  z-index: 1000;
  p {  text-align: center; }
  img {
    height: 12px;
    width: 36px;
  }
}
</style>
