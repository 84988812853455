import service from '@/utils/service'

export function getInfoFocus(params) {
  return service({
    url: `/front-article/articleadvertsapi/get_adverts`,
    params
  })
}

export function getInfoTitle(params) {
  return service({
    url: '/front-article/articletypesapi/get_types',
    params
  })
}

export function getInfoList(params) {
  return service({
    url: `/front-article/articlesapi/get_all`,
    params
  })
}

export function getInfoShow(params) {
  return service({
    url: `/front-article/articlesapi/get_one`,
    params
  })
}

