import service from '@/utils/service'

export function getColumn(params) {
  return service({
    url: `/front-column/v1/column/column`,
    params
  })
}

export function getColumnPoi(params) {
  return service({
    url: `/front-column/v1/column/columnInfo`,
    params
  })
}

export function getSearch(data) {
  return service({
    url: `/search/v1/indexSearch/pois`,
    method: 'post',
    data
  })
}

