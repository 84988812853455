<template>
  <div v-if="showPopup" id="floatball" class="floatball" :style="{height:(position.h)+'px'}">
    <div class="pop-title"><p @click="showPopupClose"><img src="/static/images/map/info03.png"></p></div>
    <div
      id="moveable"
      class="moveable"
      :style="{height:position.h+'px'}"
      @touchstart="down"
      @touchmove="move"
      @touchend="end"
    >
      <div id="showHeight" class="showHeight">

        <div class="scrollLine">
          <div v-if="routes">
            <div v-for="(item, index) of routes" :key="index" class="route">
              <div class="route-head">
                <span class="route-color" @click="routeCenter(item)">{{ index+1 }}</span>
                <div class="route-title">
                  <h2><router-link :to="'/mapLine/' + item.id">{{ item.name }}</router-link></h2>
                  <p v-if="item.trip_mode==='straight'" class="route-text">{{ item.length }}km</p>
                  <p v-else-if="item.trip_mode==='DrivingRoute'" class="route-text">{{ item.length }}km | 驾车</p>
                  <p v-else class="route-text">{{ item.length }}km | 步行</p>
                </div>
                <span class="route-menu">
                  <van-switch v-model="item.show" size="20" active-color="#6F85FF" inactive-color="#C9C9C9" style="margin-top:3px;" @click="showRoute(item)" />
                </span>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import eventBus from '@/utils/eventBus'
import { TabbarItem, Popup, Tab, Tabs, Switch } from 'vant'
export default {
  name: 'NavTabs',
  components: {
    [TabbarItem.name]: TabbarItem,
    [Popup.name]: Popup,
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    [Switch.name]: Switch
  },
  props: {
    routes: { type: Array, default: () => [] }
  },
  data() {
    return {
      showSelect: true,
      active: 0,
      projectId: '',
      like: false, // 是否收藏线路
      flags: false,
      position: {
        x: 0,
        y: 0,
        h: 170
      },
      startY: '',
      moveY: '',
      lastY: '',
      showHeight: '',
      showPopup: false,
      columns: [],
      columnTwo: [],
      columnThree: [],
      threeName: '',
      activeTwo: 0,
      activeThree: 0
    }
  },
  created() {
    this.projectId = this.$route.params && this.$route.params.projectId
    eventBus.$on('lineShow', () => {
      this.showPopup = true
    })
  },
  methods: {
    // 切换路线显示/隐藏
    showRoute(route) {
      route.show = !route.show
      this.$emit('routerChange', 'routes', { id: route.id, show: route.show })
      if (route.show) {
        this.position.h = 170
        this.$emit('routePoiCenter', route.id)
      }
    },
    routeCenter(route) {
      this.$emit('routePoiCenter', route.id)
    },
    down(event) {
      this.showHeight = document.getElementById('showHeight').clientHeight
      this.flags = true
      let touch
      if (event.touches) {
        touch = event.touches[0]
      } else {
        touch = event
      }
      this.startY = touch.clientY
    },
    move(event) {
      if (this.flags) {
        let touch
        if (event.touches) {
          touch = event.touches[0]
        } else {
          touch = event
        }
        // 定位滑块的位置
        if (!this.lastY) {
          this.moveY = this.startY - touch.clientY
        } else {
          this.moveY = this.lastY - touch.clientY
        }
        if (this.position.h > (this.showHeight + 100)) {
          this.position.h = this.showHeight + 90
        }
        this.position.h = this.position.h + this.moveY
        if (this.position.h < 170) {
          this.position.h = 170
        }
        this.lastY = touch.clientY
        // document.addEventListener('touchmove', function() {
        //   event.preventDefault()
        // }, false)
      }
    },
    end(event) {
      // const floatballHeight = document.getElementById('floatball').offsetTop
      // console.log(floatballHeight)
      // if (floatballHeight < 280 && floatballHeight > 0) {
      //   this.flags = false
      // } else if (floatballHeight > 280 && floatballHeight < 460) {
      //   this.flags = false
      // } else {
      //   this.flags = false
      // }
      this.flags = false
      this.lastY = ''
    },
    showPopupClose() {
      this.$emit('routerPopupClose')
      if (this.position.h > 600) {
        this.position.h = 170
      } else {
        this.position.h = 170
        this.showPopup = false
      }
    }
  }

}
</script>

<style lang="less" scoped>
@import '~@/styles/public.less';
.openPop {
  width: 90px;
  height: 36px;
  display: block;
  position: fixed;
  background: #fff;
  border-radius: 36px;
  box-shadow: 4px 4px 4px rgba(10, 36, 99, 0.1);
  right: 10px;
  bottom: 80px;
  z-index: 999;
  p{
    font-size: 14px;
    text-align: center;
    color: #333;
    line-height: 36px;
  }
  span{
    width: 20px;
    height: 20px;
    display: block;
    position: absolute;
    right: -5px;
    top: -5px;
    color: #fff;
    background: #ED1526;
    border-radius: 20px;
    line-height: 20px;
    font-size: 12px;
  }
}
.openSelect{
  position: fixed;
  right: 10px;
  bottom: 150px;
  z-index: 999;
  img { height: 0.9rem;  width: 0.9rem;  }
}

.scrollLine{ .autoHeight(); }
// 线路模块
.route { width: 94%; height: 60px; margin: 0 auto;
  .route-head {
    width: 100%;
    height: 60px;
    line-height: 60px;
    font-size: 12px;
    display: flex;
    .route-color {
      width: 26px;
      height: 26px;
      margin-top: 10px;
      border-radius: 26px;
      line-height: 26px;
      text-align: center;
      color: @white;
      background: #3C3C3C;
    }
    .route-title {
      width: 80%;
      padding: 5px 3%;
      h2{
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 15px;
        line-height: 24px;
        a{ color: #333; }
      }
      p{ font-size: 12px; line-height: 20px; color: #444;}
    }
    .route-menu {
      height: 100%;
      text-align: right;
      span {
        line-height: 25px;
        font-size: 12px;
      }
      .active{
        background-color: #f27b48;
        color: #fff;
        font-size: 12px;
        border: 1px solid #f27b48;
      }
    }
  }
  border-bottom: 1px solid rgba(0, 0, 0, 0.04);
}
.floatball{
  width: 100%;
  max-height: 680px;
  z-index: 999;
  position: fixed;
  bottom: 0;
  left: 0;
  overflow: hidden;
  background-color: #fff;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  box-shadow: 0px -5px 10px rgba(194, 194, 194, 0.5);
}
.moveable{
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  padding-top: 30px;
  box-sizing: border-box;

}
.showHeight{
  .autoHeight();
  .list{
    .autoHeight();
    padding:5px 10px 0;
    .list-title{
      font-size: 14px;
      min-width: 30%;
      background: #f1f1f1;
      float: left;
      padding: 0 4%;
      margin-left: 2.5%;
      margin-bottom: 3%;
      text-align: center;
      color: #666;
      line-height: 36px;
      border-radius: 20px;
      position: relative;
      img{
        position: absolute;
        left: -10px;
        top: -0;
        height: 30px;
        width: auto
      }
    }
    .select{
      background: #3165ec;
      color: #fff;
    }
    .select-red{
      background: #c33;
      color: #f8ec7c;
    }
  }
  .list-three{
    .autoHeight();
    padding:0px 10px 5px;
    .list-title{
      font-size: 14px;
      min-width: 30%;
      background: #f1f1f1;
      float: left;
      padding: 0 4%;
      margin-left: 2.5%;
      margin-bottom: 3%;
      text-align: center;
      color: #666;
      line-height: 36px;
      border-radius: 20px;
    }
    .red{ color: #c33;}
    .select{
      background: #6f85ff;
      color: #fff;
    }
    .select-red{
      background: #c33;
      color: #f8ec7c;
    }
  }
}
.pop-title {
  width: 10%;
  height: 24px;
  padding: 6px 0;
  position: absolute;
  top: 0;
  left: 45%;
  z-index: 1000;
  p {  text-align: center; }
  img {
    height: 12px;
    width: 36px;
  }
}
</style>
