<template>
  <div v-if="showIframeUrl" class="iframe_bg">
    <!-- <div class="close">
      <a @click="handleShow()"><img src="@/assets/images/close.png"></a>
    </div> -->
    <div class="goback">
      <p @click="handleShow()">返回</p>
    </div>
    <iframe :src="iframeUrl" width="100%" height="100%" frameborder />
  </div>
</template>

<script>
import eventBus from '@/utils/eventBus'
export default {
  name: 'Iframe',
  data() {
    return {
      showIframeUrl: false,
      iframeUrl: ''
    }
  },
  watch: {
    '$route': 'getIframeUrl',
    iframeUrl: function() {
      this.getIframeUrl()
    }
  },
  created() {
    eventBus.$on('showIframeUrl', (url) => {
      this.showIframeUrl = !this.showIframeUrl
      this.iframeUrl = url
    })
  },
  methods: {
    handleShow() {
      this.showIframeUrl = false
    },
    getIframeUrl() {
      if (this.iframeUrl !== '') {
        this.showIframeUrl = true
      } else {
        this.showIframeUrl = false
      }
    }
  }
}
</script>

<style scoped>
.iframe_bg{ width: 100vw; height: 100vh; position: fixed; left: 0; top:0; z-index: 99998; background: #fff; }
.iframe_bg .close{ width: 30px; height: 30px; position: absolute; right: 10px; bottom: 150px; z-index: 99999;}

.iframe_bg  .goback{ width: 70px; height: 70px; background: rgba(0, 0, 0, .5); position: absolute; right: 10px; bottom: 150px; z-index: 99999;}
.iframe_bg  .goback p{ font-size: 20px; line-height: 70px; text-align:center; color: #fff;}

</style>
