<template>
  <div>
    <div class="search-bg">
      <div v-if="showSearch" class="search-fr" @click="handleOpen"><img src="@/assets/images/search01.png"></div>
      <div v-if="showInput" class="search-fr" @click="handleClose"><img src="@/assets/images/close.png"></div>
      <div class="search-ft"><input v-model="keyWord" :class="{inputWidth:showInput}" type="search" placeholder="请输入关键词" @keyup.13="handleSearch"></div>
      <div class="search-fl" @click="handleSearch">搜索</div>
    </div>
    <div v-if="isSearch" class="search-con">
      <ul>
        <van-list
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          offset="30"
          @load="onLoad"
        >
          <li v-for="(item,index) of searchLists" :key="index">
            <a @click="handleSearchLink(item.body)">{{ item.body.name }}</a><b>{{ item.body.distance }} km</b>
          </li>
        </van-list>
      </ul>

    </div>
  </div>
</template>

<script>
import { getSearch } from '@/api/column'
import { List } from 'vant'

export default {
  name: 'Search',
  components: {
    [List.name]: List
  },
  data() {
    return {
      showSearch: true,
      showInput: false,
      keyWord: '',
      searchLists: [],
      isSearch: false,
      page: 1,
      loading: false,
      finished: false
    }
  },
  methods: {
    async onLoad() {
      const data = {
        query: this.keyWord,
        lon: this.$store.state.location.lng,
        lat: this.$store.state.location.lat,
        unit: 'km',
        page: this.page,
        size: 5
      }
      const res = await getSearch(data)
      this.searchLists.push.apply(this.searchLists, res.data.results)
      this.page++
      this.loading = false
      // 数据全部加载完成
      if (this.page > res.data.meta.last_page) {
        this.finished = true
      }
    },
    async handleSearch() {
      if (this.keyWord !== '') {
        this.isSearch = true
        this.searchLists = []
        this.page = 1
        this.loading = true
        this.finished = false
        this.onLoad()
      }
    },
    handleSearchLink(info) {
      this.$emit('searchResult', info)
      this.isSearch = false
      this.keyWord = ''
    },
    handleOpen() {
      this.showSearch = false
      this.showInput = true
    },
    handleClose() {
      this.showSearch = true
      this.showInput = false
      this.isSearch = false
      this.keyWord = ''
    }

  }
}
</script>

<style lang="less" scoped>
@import '~@/styles/public.less';
.search-bg{
  position: absolute;
  top: 0.3rem;
  left: 10px;
  height: 0.8rem;
  border:1px #ddd solid;
  background :#fff;
  opacity :0.9;
  z-index :999;
  border-radius:0.8rem;
  padding :0.1rem 0.2rem 0.1rem 0.1rem;
  .search-fl{
    float :right;
    font-size :0.3rem;
    line-height :0.6rem;
    padding :0 0.2rem;
    border-left :1px #ddd solid;
  }
  .search-ft{
    float :left;
    input{
      width :1px;
      height :0.6rem;
      font-size :0.28rem;
      line-height :0.6rem;
      border:none;
      float :left;
      transition :width 1s;
    }
    .inputWidth{
      width :3rem;
      padding :0 0.2rem;
    }
  }
  .search-fr{
    float :left;
    width :0.8rem;
    height :0.6rem;
    padding :0.1rem 0.2rem;
    img{
      width :0.4rem;
      height :0.4rem;
    }
  }
  .router-slid-enter-active, .router-slid-leave-active{
    transition: all .4s;
  }
  .router-slid-enter, .router-slid-leave-active{
    transform: translate3d(0, 3rem, 0);
    opacity: 0;
  }
}
.search-con{
  width :6rem;
  position: absolute;
  height :2.3rem;
  top: 1.1rem;
  left: 10px;
  background :#fff;
  opacity :0.9;
  z-index :99997;
  padding :0.1rem 0.2rem 0.1rem 0.1rem;
  overflow-y :scroll;
  li{
    font-size: 0.24rem;
    line-height :0.45rem;
    display: flex;
  }
  p{
    text-align :center;
    font-size: 0.2rem;
    line-height :0.4rem;
  }
  a{
    width: 4.7rem;
    .LimitWord();
    -webkit-line-clamp :1;
    color :#333;
    font-size: 0.24rem;
  }
  span{
    width :1.5rem;
    display :block;
    float :right;
    padding :0 0.1rem;
    text-align :right;
    overflow :hidden;
  }
  b{
    width :1.3rem;
    color: #666;
    font-size: 0.2rem;
    display :block;
    float :right;
    overflow :hidden;
    font-weight :normal;
    padding :0 0.1rem;
    text-align :right;
  }
}
</style>
