/* eslint-disable no-undef */
const ak = '9xScsTjlKuGUe0CKb0DDt10Au1Xf9M9O' // 百度的地图密钥
/**
 * 异步加载百度地图
 * @returns {Promise}
 * @constructor
 */

function loadBaiDuMap() {
  return new Promise(function(resolve, reject) {
    try {
      resolve(BMapGL)
    } catch (err) {
      window.init = function() {
        resolve(BMapGL)
      }
      const script = document.createElement('script')
      script.type = 'text/javascript'
      script.src = `https://api.map.baidu.com/api?v=1.0&type=webgl&ak=${ak}&callback=init`
      script.onerror = reject
      document.body.appendChild(script)
    }
  })
}
export { loadBaiDuMap }

function loadBMapGLLib() {
  return loadBaiDuMap().then(BMapGL => {
    let loaded = false
    try {
      loaded = (BMapGLLib)
    } catch (err) {
      loaded = false
    }
    if (!loaded) {
      const script = document.createElement('script')
      script.type = 'text/javascript'
      script.src = `/static/GeoUtils.js`
      document.body.appendChild(script)
      console.log('BMapGLLib!')
    } else {
      console.log('BMapGLLib is loaded!')
    }
    return BMapGL
  })
}
export { loadBMapGLLib }

