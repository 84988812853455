<template>
  <div class="home">
    <!-- 开场动画 -->
    <div v-if="startImg" :class="{hideImg:!this.$store.state.openStart}" class="startImg">
      <img :src="startImg">
    </div>

    <div class="map-btn map-btn-left">
      <p @click="handleMapAll()"><img src="static/images/map/map06.png"><br>全域</p>
      <div class="map-line" />
      <p v-if="!showLocation" @click="handleLocation()"><img src="static/images/map/map07.png"><br>附近</p>
      <p v-else @click="closeLocation()"><img src="static/images/map/map08.png">附近</p>
    </div>
    <!-- <div v-if="showLocation" class="map-btn map-btn-bottom">
      <p v-if="!showLocation3D" @click="handleLocation2D()"><b>2D</b></p>
      <p v-else @click="handleLocation3D()"><b>3D</b></p>
    </div> -->
    <div v-if="markerTypeShow" class="marker-window marker-index">
      <div v-if="markerTypeInfo.layer_name==='旅游厕所'">
        <div class="marker-close" @click="closeMakerShow(markerTypeInfo.layer_id)"><img src="/static/images/map/info-close.png"></div>
        <div class="listHot">
          <div class="list-img">
            <div v-if="markerTypeInfo.info">
              <router-link v-if="markerTypeInfo.image" :to="'/mapShow/' + markerTypeInfo.id">
                <span>{{ (markerTypeInfo.distance / 1000).toFixed(1) }} km</span><img :src="markerTypeInfo.image">
              </router-link>
              <router-link v-else :to="'/mapShow/' + markerTypeInfo.id">
                <span>{{ (markerTypeInfo.distance / 1000).toFixed(1) }} km</span><img src="/static/images/logo.png">
              </router-link>
            </div>
            <div v-else>
              <p v-if="markerTypeInfo.image"><span>{{ (markerTypeInfo.distance / 1000).toFixed(1) }} km</span><img :src="markerTypeInfo.image"></p>
              <p v-else><span>{{ (markerTypeInfo.distance / 1000).toFixed(1) }} km</span><img src="/static/images/logo.png"></p>
            </div>
          </div>
          <div class="list-txt">
            <h2 v-if="markerTypeInfo.info"><router-link :to="'/mapShow/' + markerTypeInfo.id">{{ markerTypeInfo.name }}</router-link></h2>
            <h2 v-else>{{ markerTypeInfo.name }}</h2>
            <p v-if="markerTypeInfo.recommend">{{ markerTypeInfo.recommend }}</p>
            <h5 v-if="markerTypeInfo.address"><b>详细地址：</b>{{ markerTypeInfo.address }}</h5>
            <div @click="handleBaiduUrl(markerTypeInfo)">
              <span class="marker-go" @click="handleBaiduUrl(markerTypeInfo)"><img src="/static/images/map/info01.png">去这里</span>
            </div>
          </div>
        </div>
        <div class="marker-bottom">
          <div v-for="(item,index) in markerTypeInfo.attributes" :key="index">
            <div v-if="item.value !== '0'" class="marker-bottom-ls"><span>{{ item.name }} : {{ item.value }}</span></div>
          </div>
        </div>
      </div>
      <div v-else-if="markerTypeInfo.layer_name==='停车场'">
        <div class="marker-close" @click="closeMakerShow(markerTypeInfo.layer_id)"><img src="/static/images/map/info-close.png"></div>
        <div class="listHot">
          <div class="list-img">
            <div v-if="markerTypeInfo.info">
              <router-link v-if="markerTypeInfo.image" :to="'/mapShow/' + markerTypeInfo.id">
                <span>{{ (markerTypeInfo.distance / 1000).toFixed(1) }} km</span><img :src="markerInfo.image">
              </router-link>
              <router-link v-else :to="'/mapShow/' + markerTypeInfo.id">
                <span>{{ (markerTypeInfo.distance / 1000).toFixed(1) }} km</span><img src="/static/images/logo.png">
              </router-link>
            </div>
            <div v-else>
              <p v-if="markerTypeInfo.image"><span>{{ (markerTypeInfo.distance / 1000).toFixed(1) }} km</span><img :src="markerTypeInfo.image"></p>
              <p v-else><span>{{ (markerTypeInfo.distance / 1000).toFixed(1) }} km</span><img src="/static/images/logo.png"></p>
            </div>
          </div>
          <div class="list-txt">
            <h2 v-if="markerTypeInfo.info"><router-link :to="'/mapShow/' + markerTypeInfo.id">{{ markerTypeInfo.name }}</router-link></h2>
            <h2 v-else>{{ markerTypeInfo.name }}</h2>
            <h5 v-if="markerTypeInfo.address"><b>详细地址：</b>{{ markerTypeInfo.address }}</h5>
            <div @click="handleBaiduUrl(markerTypeInfo)">
              <span class="marker-go" @click="handleBaiduUrl(markerTypeInfo)"><img src="/static/images/map/info01.png">去这里</span>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <div class="marker-close" @click="closeMakerShow(markerTypeInfo.layer_id)"><img src="/static/images/map/info-close.png"></div>
        <div class="listHot">
          <div class="list-img">
            <div v-if="markerTypeInfo.info">
              <router-link v-if="markerTypeInfo.image" :to="'/mapShow/' + markerTypeInfo.id">
                <span>{{ (markerTypeInfo.distance / 1000).toFixed(1) }} km</span><img :src="markerTypeInfo.image">
              </router-link>
              <router-link v-else :to="'/mapShow/' + markerTypeInfo.id">
                <span>{{ (markerTypeInfo.distance / 1000).toFixed(1) }} km</span><img src="/static/images/logo.png">
              </router-link>
            </div>
            <div v-else>
              <p v-if="markerTypeInfo.image"><span>{{ (markerTypeInfo.distance / 1000).toFixed(1) }} km</span><img :src="markerTypeInfo.image"></p>
              <p v-else><span>{{ (markerTypeInfo.distance / 1000).toFixed(1) }} km</span><img src="/static/images/logo.png"></p>
            </div>
          </div>
          <div class="list-txt">
            <h2 v-if="markerTypeInfo.info"><router-link :to="'/mapShow/' + markerTypeInfo.id">{{ markerTypeInfo.name }}</router-link></h2>
            <h2 v-else>{{ markerTypeInfo.name }}</h2>
            <p v-if="markerTypeInfo.recommend">{{ markerTypeInfo.recommend }}</p>
            <div v-for="(item,index) in markerTypeInfo.attributes" :key="index">
              <div v-if="item.value !== '0'" class="marker-bottom-ls">
                <h5 v-if="markerTypeInfo.phone"><b>{{ item.name }}：</b>{{ item.value }}</h5>
              </div>
            </div>
            <h5 v-if="markerTypeInfo.phone"><b>救助电话：</b>{{ markerTypeInfo.phone }}</h5>
            <h5 v-if="markerTypeInfo.address"><b>详细地址：</b>{{ markerTypeInfo.address }}</h5>
            <div @click="handleBaiduUrl(markerTypeInfo)">
              <span class="marker-go" @click="handleBaiduUrl(markerTypeInfo)"><img src="/static/images/map/info01.png">去这里</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="markerShow" class="marker-window">
      <div>
        <div class="marker-close" @click="closeMakerShow()"><img src="/static/images/map/info-close.png"></div>
        <div class="listHot">
          <div class="list-img">
            <div v-if="markerInfo.info">
              <router-link v-if="markerInfo.image" :to="'/mapShow/' + markerInfo.id">
                <span>{{ (markerInfo.distance / 1000).toFixed(1) }} km</span><img :src="markerInfo.image">
              </router-link>
              <router-link v-else :to="'/mapShow/' + markerInfo.id">
                <span>{{ (markerInfo.distance / 1000).toFixed(1) }} km</span><img src="/static/images/logo.png">
              </router-link>
            </div>
            <div v-else>
              <p v-if="markerInfo.image"><span>{{ (markerInfo.distance / 1000).toFixed(1) }} km</span><img :src="markerInfo.image"></p>
              <p v-else><span>{{ (markerInfo.distance / 1000).toFixed(1) }} km</span><img src="/static/images/logo.png"></p>
            </div>
          </div>
          <div class="list-txt">
            <h2 v-if="markerInfo.info"><router-link :to="'/mapShow/' + markerInfo.id">{{ markerInfo.name }}</router-link></h2>
            <h2 v-else>{{ markerInfo.name }}</h2>
            <p v-if="markerInfo.recommend">{{ markerInfo.recommend }}</p>
            <h5 v-if="markerInfo.phone"><b>电话：</b>{{ markerInfo.phone}}</h5>
            <h5 v-if="markerInfo.address"><b>详细地址：</b>{{ markerInfo.address }}</h5>
          </div>
        </div>
        <div class="marker-bottom">
          <div v-for="(item,index) in markerInfo.attributes" :key="index">
            <div v-if="item.name.indexOf('详情链接') === -1 && item.name.indexOf('无障碍游览') === -1 && item.value" class="marker-bottom-l" @click="handleIframe(item.value)"><span>{{ item.name }}</span></div>
          </div>
          <div class="marker-bottom-l" @click="handleBaiduUrl(markerInfo)"><span>去这里</span></div>
          <div v-if="toilet">
            <div v-if="toiletBtn" class="marker-bottom-l" @click="getMarkers(markerInfo,185)"><span>厕所</span></div>
          </div>
          <div v-if="parking">
            <div v-if="parkingBtn" class="marker-bottom-l" @click="getMarkers(markerInfo,186)"><span>停车场</span></div>
          </div>
          <div v-if="tourist">
            <div v-if="touristBtn" class="marker-bottom-l" @click="getMarkers(markerInfo,187)"><span>游客中心</span></div>
          </div>
          <div v-if="AED">
            <div v-if="AEDBtn" class="marker-bottom-l" @click="getMarkers(markerInfo,312)"><span>AED</span></div>
          </div>
          <div v-if="nursery">
            <div v-if="nurseryBtn" class="marker-bottom-l" @click="getMarkers(markerInfo,314)"><span>母婴室</span></div>
          </div>
          <div v-for="tag in markerInfo.tags" :key="tag.id">
            <div v-if="tag.name === '无障碍游览'" class="marker-bottom-l" @click="handleTag(markerInfo)"><span>{{ tag.name }}</span></div>
          </div>
        </div>
      </div>
    </div>
    <div id="container" class="map" />
    <map-search
      @searchResult="searchResult"
    />
    <nav-tabs-v-2
      ref="navtabs"
      :routes="routes"
      @routerPopupClose="routerPopupClose"
      @routerChange="routerChange"
      @routePoiCenter="routePoiCenter"
    />
    <iframe-url />
    <moveable
      ref="moveable"
      :routes="routes"
      @changeColumnId="changeColumnId"
    />
    <tabbar
      :columns="columns"
      @changeColumnId="changeColumnId"
      @closeMakerShow="closeMakerShow"
    />
  </div>
</template>

<script>
import { getPoi, getRoutesPoi, getMapsPoi, getMaps, getPoiTypes } from '@/api/mapV2'
import { getInfoFocus } from '@/api/info.js'
import { loadBaiDuMap } from '@/utils/bmap.js'
import Tabbar from '@/components/tabBar'
import Moveable from '@/components/moveable'
import IframeUrl from '@/components/iframeUrl'
import { getColumn, getColumnPoi } from '@/api/column.js'
import eventBus from '@/utils/eventBus'
import MapSearch from './components/mapSearch.vue'
import NavTabsV2 from '@/components/navTabs'
// import NavTabsV2 from './components/navTabsV2.vue'
import { ImagePreview } from 'vant'

export default {
  name: 'Home',
  components: {
    Tabbar,
    Moveable,
    IframeUrl,
    MapSearch,
    NavTabsV2,
    [ImagePreview.Component.name]: ImagePreview.Component
  },
  data() {
    return {
      keyLng: 0,
      keyLat: 0,
      startImg: '',
      map: '',
      BMapGL: '',
      projectName: '',
      center: {},
      zoom: 10,
      allCenter: {},
      allZoom: 13,
      types: [],
      markers: [],
      routes: [],
      area: {},
      showLocation: false,
      showLocation3D: false,
      myLocation: '',
      markerShow: false,
      markerTypeShow: false,
      markerInfo: {},
      markerTypeInfo: {},
      markerId: '',
      routeId: '',
      areaId: '',
      oldMarker: '',
      mapLevel: '',
      mapStyle: {
        strokeColor: '#478dfa',
        strokeWeight: 2,
        strokeOpacity: 0.4,
        strokeStyle: 'dotted',
        fillColor: '#87ceeb',
        fillOpacity: 0.2
      },
      markerLabelStyle: {
        color: 'black',
        borderRadius: '40px',
        borderColor: '#fff',
        padding: '3px 8px 3px 30px',
        fontSize: '13px',
        height: '20px',
        lineHeight: '20px',
        boxShadow: '4px 4px 4px rgba(176, 179, 189, 0.48)'
      },
      routeStyle: {
        strokeColor: '#6F85FF',
        strokeWeight: 4,
        strokeOpacity: 1
      },
      routeLabelStyle: {
        textAlign: 'center',
        color: '#fff',
        borderRadius: '30px',
        borderColor: '#F7692D',
        fontSize: '14px',
        width: '20px',
        height: '20px',
        lineHeight: '20px',
        background: '#F7692D',
        boxShadow: '4px 4px 4px #B0B3BD',
        // pointerEvents: 'none',
        zIndex: -999
      },
      areaPolygon: '',
      columns: [],
      columnId: '',
      initialId: '',
      toilet: '',
      tourist: '',
      AED: '',
      nursery: '',
      parking: '',
      lines: '',
      tourImg: '',
      toiletBtn: false,
      touristBtn: false,
      parkingBtn: false,
      AEDBtn: false,
      nurseryBtn: false
    }
  },
  watch: {
    zoom: {
      handler() {
        this.markerIsShow()
      },
      immediate: false
    }
  },
  created() {
    this.getAllColumns()
    this.getTypes()
    this.getStartImg()
    if (navigator.geolocation) {
      navigator.geolocation.watchPosition(this.updateLocation, this.handleLocationError, {
        timeout: 30000
      })
    }
    getMaps().then(res => {
      this.center = res.payload.data.center_poi
      if (!this.$store.state.location) {
        this.$store.commit('CHANGELOCATION', {
          lng: this.center.lng,
          lat: this.center.lat
        })
      }
      this.projectName = res.payload.data.title
      document.title = this.projectName
      const mapLevel = res.payload.data.level.split(',')
      this.zoom = Number(mapLevel[0])
      this.allCenter = res.payload.data.center_poi
      this.allZoom = Number(mapLevel[0])
      if (this.center && this.zoom) {
        this.initMap()
      }
    })
    setTimeout(() => {
      this.$store.state.openStart = false
    }, 5000)
  },
  methods: {
    async getAllColumns() {
      const res = await getColumn()
      this.columns = res.data
      eventBus.$emit('columns', this.columns)
      if (this.columns[0].children) {
        if (this.columns[0].children[0].children) {
          this.initialId = this.columns[0].children[0].children.id
        } else {
          this.initialId = this.columns[0].children[0].id
        }
      }
    },
    async getColumnsPoi() {
      this.markers = []
      this.routes = []
      this.map.clearOverlays()
      this.map.flyTo(this.allCenter, this.allZoom)
      const param = {
        column_id: this.initialId
      }
      const res = await getColumnPoi(param)
      this.markers = res.data.pois
      if (this.markers && this.markers.length !== 0) {
        this.renderOverlays('markers', {})
      }
      this.routes = res.data.routes
      for (const num in this.routes) {
        this.routes[num].show = true
        // if (num < 2) {
        //   this.routes[num].show = true
        // }
      }
      if (this.routes && this.routes.length !== 0) {
        this.map.flyTo(this.allCenter, 10)
        this.renderOverlays('routes', 'all')
        // this.$refs.moveable.showPopup = false
        // this.$refs.navtabs.showPopup = true
      }
    },
    async getTypes() {
      const res = await getPoiTypes()
      this.types = []
      const types = res.payload.types
      types.forEach(type => {
        if (type.name.indexOf('厕所') !== -1 || type.name.indexOf('游客') !== -1 || type.name.indexOf('停车') !== -1) {
          this.types.push(type)
        }
      })
    },
    async getStartImg() {
      const param = {
        type: 3
      }
      const res = await getInfoFocus(param)
      if (res.data.length !== 0) {
        this.startImg = res.data[0].images[0].image
      }
    },
    initMap() {
      loadBaiDuMap().then(BMapGL => {
        const map = new BMapGL.Map('container')
        map.centerAndZoom(new BMapGL.Point(this.center.lng, this.center.lat), this.zoom)
        map.setDisplayOptions({
          poiIcon: false
        })
        this.map = map
        this.BMapGL = BMapGL
        if (this.$route && this.$route.query.publicPoi) {
          this.searchPublic()
        } else {
          this.getColumnsPoi()
        }
        this.getToilet()
        this.getTourist()
        this.getParking()
        this.getAED()
        this.getNursery()
        this.getLines()
        map.addEventListener('zoomend', e => {
          const ZoomNum = map.getZoom()
          this.zoom = ZoomNum
        })
      })
    },
    async getToilet() {
      const param = {
        type: 185
      }
      const toilet = await getPoi(param)
      this.toilet = toilet.payload.list
    },
    async getParking() {
      const param = {
        type: 186
      }
      const parking = await getPoi(param)
      this.parking = parking.payload.list
    },
    async getTourist() {
      const param = {
        type: 187
      }
      const tourist = await getPoi(param)
      this.tourist = tourist.payload.list
    },
    // 20220914 AED
    async getAED() {
      const param = {
        type: 312
      }
      const AED = await getPoi(param)
      this.AED = AED.payload.list
    },
    // 20230804 nursery
    async getNursery() {
      const param = {
        type: 314
      }
      const nursery = await getPoi(param)
      this.nursery = nursery.payload.list
    },
    // 20220916 根据 url参数 查找周围的设施
    async searchPublic() {
      if (this.$route && this.$route.query.publicPoi) {
        var _publicPoi = this.$route.query.publicPoi
        if (_publicPoi === '280661280506286080') {
          this.$refs.moveable.showPopup = false
          this.changeColumnId(_publicPoi)
        } else {
          this.map.clearOverlays()
          this.markers = []
          var param = {
            type: _publicPoi
          }
          var publicPoiData = await getPoi(param)
          this.markers = publicPoiData.payload.list
          this.$refs.moveable.showPopup = false
          this.markerShow = false
          this.markerTypeShow = false
          this.$refs.navtabs.showPopup = false
          this.renderOverlays('markers', {})
          this.handleLocation()
        }
      }
    },
    searchResult(info) {
      this.map.clearOverlays()
      this.center = {
        lng: info.lng,
        lat: info.lat
      }
      this.zoom = 15
      this.map.flyTo(this.center, this.zoom)
      this.markers = []
      this.markers.push(info)
      this.$refs.moveable.showPopup = false
      this.markerShow = false
      this.markerTypeShow = false
      this.$refs.navtabs.showPopup = false
      this.renderOverlays('markers', {})
    },
    // 接口获取点线面
    async getMarkers(markerInfo, id) {
      this.map.clearOverlays()
      this.center = {
        lng: markerInfo.lng,
        lat: markerInfo.lat
      }
      this.zoom = 15
      this.map.flyTo(this.center, this.zoom)
      this.markers = []
      if (id === 185) {
        this.toilet.forEach(item => {
          if (item.name.indexOf(markerInfo.name) !== -1) {
            this.markers.push(item)
          }
        })
        this.renderOverlays('markers', {})
      } else if (id === 187) {
        this.tourist.forEach(item => {
          if (item.name.indexOf(markerInfo.name) !== -1) {
            this.markers.push(item)
          }
        })
        this.renderOverlays('markers', {})
      } else if (id === 312) {
        this.AED.forEach(item => {
          if (item.name.indexOf(markerInfo.name) !== -1) {
            this.markers.push(item)
          }
        })
        this.renderOverlays('markers', {})
      } else if (id === 314) {
        this.nursery.forEach(item => {
          if (item.name.indexOf(markerInfo.name) !== -1) {
            this.markers.push(item)
          }
        })
        this.renderOverlays('markers', {})
      } else {
        this.parking.forEach(item => {
          if (item.name.indexOf(markerInfo.name) !== -1) {
            this.markers.push(item)
          }
        })
        this.renderOverlays('markers', {})
      }
    },
    async getLines() {
      const res = await getRoutesPoi()
      this.lines = res.payload.list
      this.lines.map((line, index) => {
        line.show = index <= 1
        return line
      })
    },
    handleTag(markerInfo) {
      this.tourImg = ''
      markerInfo.attributes.forEach(info => {
        if (info.name.indexOf('无障碍游览') !== -1) {
          this.tourImg = info.value
        }
      })
      if (this.tourImg) {
        ImagePreview({
          images: [
            this.tourImg
          ],
          closeable: true
        })
      } else {
        this.getRoutes(markerInfo)
      }
    },
    async getRoutes(markerInfo) {
      this.map.clearOverlays()
      this.center = {
        lng: markerInfo.lng,
        lat: markerInfo.lat
      }
      this.zoom = 15
      this.map.flyTo(this.center, this.zoom)
      this.routes = []
      this.lines.forEach(item => {
        if (item.name.indexOf(markerInfo.name) !== -1) {
          this.routes.push(item)
        }
      })
      this.markerShow = false
      this.$refs.navtabs.showPopup = true
      if (this.routeId) {
        for (let num = 0; num < this.routes.length; num++) {
          if (this.routes[num].id === Number(this.routeId)) {
            this.routes[num].show = true
            this.types.forEach(type => {
              type.show = false
            })
            this.routePoiCenter(Number(this.routeId))
            break
          }
        }
      } else {
        for (const num in this.routes) {
          if (num < 1) {
            this.routes[num].show = true
          }
        }
      }
      this.renderOverlays('routes', 'all')
    },
    routerPopupClose() {
      this.$refs.navtabs.showPopup = false
      this.$refs.moveable.showPopup = true
    },
    routerChange(overlayType, info) {
      if (info.show) {
        if (overlayType === 'routes') {
          for (const route in this.routes) {
            if (String(this.routes[route].id) === String(info.id)) {
              if (this.routes[route].BPolyline) {
                this.routes[route].BPolyline.forEach(line => {
                  line.show()
                })
              }
              if (this.routes[route].BLabel) {
                this.routes[route].BLabel.forEach(label => {
                  label.show()
                })
              }

              if (this.routes[route].BMarker) {
                this.routes[route].BMarker.forEach(marker => {
                  marker.show()
                })
              }
            }
          }
        }
      } else {
        if (overlayType === 'routes') {
          this.map.flyTo(this.allCenter, 13)
          for (const route in this.routes) {
            if (String(this.routes[route].id) === String(info.id)) {
              if (this.routes[route].BLabel) {
                this.routes[route].BLabel.forEach(label => {
                  label.hide()
                })
              }
              if (this.routes[route].BPolyline) {
                this.routes[route].BPolyline.forEach(line => {
                  line.hide()
                })
              }
              if (this.routes[route].BMarker) {
                this.routes[route].BMarker.forEach(marker => {
                  marker.hide()
                })
              }
            }
          }
        }
      }
    },
    routePoiCenter(id) {
      for (let num = 0; num < this.routes.length; num++) {
        if (this.routes[num].id === id && this.routes[num].show) {
          const view = this.map.getViewport(this.routes[num].path)
          this.zoom = view.zoom - 1
          this.center = view.center
          this.map.flyTo(this.center, this.zoom)
          this.routeId = ''
        }
      }
    },

    async getArea() {
      const res = await getMapsPoi()
      this.area = res.payload.data
      this.area.forEach(area => {
        area.path.forEach(path => {
          const areaPath = path.map(point => {
            return new this.BMapGL.Point(point.lng, point.lat)
          })

          const Polygon = new this.BMapGL.Polygon(areaPath, { ...this.mapStyle, ...area,
            strokeOpacity: 0.4,
            fillOpacity: area.fillOpacity / 100
          })
          this.areaPolygon = Polygon
          this.map.addOverlay(Polygon)
          Polygon.disableMassClear()
        })
      })
    },
    changeColumnId(id) {
      this.initialId = id
      this.getColumnsPoi()
    },
    handleIframe(url) {
      eventBus.$emit('showIframeUrl', url)
    },
    // 绘制覆盖物
    renderOverlays(overlayType, id) {
      if (overlayType === 'markers') {
        this.markers.forEach(markerNum => {
          const opts = {
            position: new this.BMapGL.Point(markerNum.lng, markerNum.lat), // 指定文本标注所在的地理位置
            offset: new this.BMapGL.Size(0, 0) // 设置文本偏移量
          }
          // 创建文本标注对象
          const marker = new this.BMapGL.Label(markerNum.name, opts)
          if (this.initialId === '280661192786612224') {
            marker.setStyle({ ...this.markerLabelStyle,
              background: `url(/static/images/map/icon-red.svg) -5px center no-repeat #fff`
            })
          } else if (this.initialId === '280661608270168064') {
            marker.setStyle({ ...this.markerLabelStyle,
              background: `url(/static/images/map/icon-ice.svg) -5px center no-repeat #fff`
            })
          } else if (this.initialId === '280661280506286080') {
            marker.setStyle({ ...this.markerLabelStyle,
              background: `url(/static/images/map/icon-no.svg) -5px center no-repeat #fff`
            })
          } else if (this.initialId === '280661440590282752') {
            marker.setStyle({ ...this.markerLabelStyle,
              background: `url(/static/images/map/icon-old.svg) -5px center no-repeat #fff`
            })
          } else if (markerNum.layer_id === 185) {
            marker.setStyle({ ...this.markerLabelStyle,
              background: `url(/static/images/map/icon-wc.svg) -5px center no-repeat #fff`
            })
          } else if (markerNum.layer_id === 187) {
            marker.setStyle({ ...this.markerLabelStyle,
              background: `url(/static/images/map/icon-play.svg) -5px center no-repeat #fff`
            })
          } else if (markerNum.layer_id === 186) {
            marker.setStyle({ ...this.markerLabelStyle,
              background: `url(/static/images/map/icon-park.svg) -5px center no-repeat #fff`
            })
          } else if (markerNum.layer_id === 312) {
            marker.setStyle({ ...this.markerLabelStyle,
              background: `url(/static/images/map/icon-aed.svg) -5px center no-repeat #fff`
            })
          } else if (markerNum.layer_id === 314) {
            marker.setStyle({ ...this.markerLabelStyle,
              background: `url(/static/images/map/icon-nursery.svg) -5px center no-repeat #fff`
            })
          } else {
            marker.setStyle({ ...this.markerLabelStyle,
              background: `url(/static/images/map/icon-view.svg) -5px center no-repeat #fff`
            })
          }

          // overlay专属标识id
          marker.typeid = markerNum.layer_id
          marker.id = markerNum.id
          markerNum.Bobj = marker
          const level = markerNum.level.split(',')
          const outZoom = this.zoom > Number(level[1]) || this.zoom < Number(level[0])
          if (outZoom) {
            markerNum.Bobj.hide()
          }
          this.map.addOverlay(marker)
          marker.onclick = (e) => {
            marker.setStyle({
              color: 'red'
            })
            this.getMarkerInfo(marker.id, marker.typeid)
            this.oldMarker = marker
            marker.setZIndex(99)
          }
        })
      } else if (overlayType === 'routes') {
        for (let num = 0; num < this.routes.length; num++) {
          if ((id !== 'all' && String(id) !== String(this.routes[num].id)) || !this.routes[num].show) {
            continue
          }
          if (!this.routes[num].path[0].id) {
            var routePoi = this.routes[num].path.map(item => {
              const marker = new this.BMapGL.Point(item.lng, item.lat)
              return marker
            })
            const Polyline = new this.BMapGL.Polyline(routePoi, { ...this.routeStyle, ...this.routes[num], strokeOpacity: 1 })
            this.map.addOverlay(Polyline)
            const opts = {
              position: new this.BMapGL.Point(this.routes[num].path[0].lng, this.routes[num].path[0].lat), // 指定文本标注所在的地理位置
              offset: new this.BMapGL.Size(-30, -15) // 设置文本偏移量
            }
            // 创建文本标注对象
            const label = new this.BMapGL.Label(num + 1, opts)
            label.setStyle({ ...this.routeLabelStyle })
            this.map.addOverlay(label)
            this.routes[num].BLabel = []
            this.routes[num].BPolyline = []
            this.routes[num].BLabel.push(label)
            this.routes[num].BPolyline.push(Polyline)
            label.onclick = (e) => {
              // this.$router.push({ path: `/mapLine/${this.routes[num].id}` })
              this.$refs.moveable.showPopup = false
              this.markerShow = false
              this.$refs.navtabs.showPopup = true
            }
          } else if (this.routes[num].trip_mode === 'DrivingRoute' && this.routes[num].path[0].id) {
            for (let j = 0; j < this.routes[num].path.length; j++) {
              this.routes[num].BLabel = []
              this.routes[num].BPolyline = []
              this.routes[num].BMarker = []
              const markerNum = this.routes[num].path[j]
              const point = new this.BMapGL.Point(markerNum.lng, markerNum.lat)
              const icon = new this.BMapGL.Icon('/static/images/map/icon-route-small.png', new this.BMapGL.Size(9, 9), {
                imageOffset: new this.BMapGL.Size(0, 0)
              })
              const marker = new this.BMapGL.Marker(point, { icon })
              marker.onclick = (e) => {
                this.getMarkerInfo(e.target.id)
                this.oldMarker = marker
              }
              // overlay专属标识id
              marker.id = this.routes[num].path[j].id
              if (marker.id === this.routes[num].path[0].id) {
                const opts = {
                  position: new this.BMapGL.Point(markerNum.lng, markerNum.lat), // 指定文本标注所在的地理位置
                  offset: new this.BMapGL.Size(-30, -15) // 设置文本偏移量
                }
                // 创建文本标注对象
                const label = new this.BMapGL.Label(num + 1, opts)
                label.setStyle({ ...this.routeLabelStyle })
                this.map.addOverlay(label)
                label.onclick = (e) => {
                  // this.$router.push({ path: `/mapLine/${this.routes[num].id}` })
                  this.$refs.moveable.showPopup = false
                  this.markerShow = false
                  this.$refs.navtabs.showPopup = true
                }
                this.routes[num].BLabel.push(label)
              }
              this.map.addOverlay(marker)
              this.routes[num].BMarker.push(marker)
            }
            const driving = {}
            const plan = {}
            for (let d = 1; d <= this.routes[num].path.length - 1; d++) {
              driving[d] = new this.BMapGL.DrivingRoute(this.map, {
                renderOptions: {
                  map: this.map,
                  autoViewport: true
                }
              })
              driving[d].setMarkersSetCallback(function(routes) {
                this.map.removeOverlay(routes[0].marker)
                this.map.removeOverlay(routes[routes.length - 1].marker)
                for (let m = 1; m < routes.length - 1; m++) {
                  const mm = routes[m].Nm
                  this.map.removeOverlay(mm)
                }
              })
              driving[d].setSearchCompleteCallback(() => {
                plan[d] = driving[d].getResults().getPlan(0).getRoute(0).getPath()
                const line = new this.BMapGL.Polyline(plan[d], { ...this.routeStyle, ...this.routes[num], strokeOpacity: 1 })
                driving[d].clearResults()
                this.map.addOverlay(line)
                this.routes[num].BPolyline.push(line)
              })
              driving[d].disableAutoViewport()
              driving[d].search(new this.BMapGL.Point(this.routes[num].path[d - 1].lng, this.routes[num].path[d - 1].lat), new this.BMapGL.Point(this.routes[num].path[d].lng, this.routes[num].path[d].lat))
            }
          } else {
            for (let j = 0; j < this.routes[num].path.length; j++) {
              this.routes[num].BLabel = []
              this.routes[num].BPolyline = []
              this.routes[num].BMarker = []
              const markerNum = this.routes[num].path[j]
              const point = new this.BMapGL.Point(markerNum.lng, markerNum.lat)
              const icon = new this.BMapGL.Icon('/static/images/map/icon-route-small.png', new this.BMapGL.Size(9, 9), {
                imageOffset: new this.BMapGL.Size(0, 0)
              })
              const marker = new this.BMapGL.Marker(point, { icon })
              marker.onclick = (e) => {
                this.getMarkerInfo(e.target.id)
                this.oldMarker = marker
              }
              // overlay专属标识id
              marker.id = this.routes[num].path[j].id
              if (marker.id === this.routes[num].path[0].id) {
                const opts = {
                  position: new this.BMapGL.Point(markerNum.lng, markerNum.lat), // 指定文本标注所在的地理位置
                  offset: new this.BMapGL.Size(20, 2) // 设置文本偏移量
                }
                // 创建文本标注对象
                const label = new this.BMapGL.Label(num + 1, opts)
                label.setStyle({ ...this.routeLabelStyle })
                this.map.addOverlay(label)
                label.onclick = (e) => {
                  // this.$router.push({ path: `/mapLine/${this.routes[num].id}` })
                  this.$refs.moveable.showPopup = false
                  this.markerShow = false
                  this.$refs.navtabs.showPopup = true
                }
                this.routes[num].BLabel.push(label)
              }
              this.map.addOverlay(marker)
              this.routes[num].BMarker.push(marker)
            }
            const walking = {}
            const plan = {}
            for (let w = 1; w <= this.routes[num].path.length - 1; w++) {
              walking[w] = new this.BMapGL.WalkingRoute(this.map, {
                renderOptions: {
                  map: this.map,
                  autoViewport: true
                }
              })
              walking[w].setMarkersSetCallback(function(routes) {
                this.map.removeOverlay(routes[0].marker)
                this.map.removeOverlay(routes[routes.length - 1].marker)
                for (let n = 1; n < routes.length - 1; n++) {
                  const mm = routes[n].Nm
                  this.map.removeOverlay(mm)
                }
              })
              walking[w].setSearchCompleteCallback((routes) => {
                plan[w] = walking[w].getResults().getPlan(0).getRoute(0).getPath()
                const line = new this.BMapGL.Polyline(plan[w], { ...this.routeStyle, ...this.routes[num], strokeOpacity: 1 })
                walking[w].clearResults()
                this.map.addOverlay(line)
                this.routes[num].BPolyline.push(line)
              })
              walking[w].search(new this.BMapGL.Point(this.routes[num].path[w - 1].lng, this.routes[num].path[w - 1].lat), new this.BMapGL.Point(this.routes[num].path[w].lng, this.routes[num].path[w].lat))
              walking[w].disableAutoViewport()
            }
          }
        }
      }
    },
    markerIsShow() {
      for (let num = 0; num < this.markers.length; num++) {
        const level = this.markers[num].level.split(',')
        const outZoom = this.zoom > Number(level[1]) || this.zoom < Number(level[0])
        if (outZoom) {
          this.markers[num].Bobj.hide()
        }
      }
    },
    async getMarkerInfo(id, typeid) {
      this.toiletBtn = false
      this.touristBtn = false
      this.parkingBtn = false
      this.AEDBtn = false
      this.nurseryBtn = false
      if (this.oldMarker && this.oldMarker.typeid) {
        this.oldMarker.setStyle({
          color: 'black'
        })
        this.oldMarker.setZIndex(9)
      }
      if (typeid === 185 || typeid === 186 || typeid === 187 || typeid === 312 || typeid === 314) {
        const data = {
          id: id,
          lng: this.$store.state.location.lng,
          lat: this.$store.state.location.lat
        }
        const res = await getPoi(data)
        if (res) {
          this.markerTypeInfo = res.payload.list[0]
        }
        this.$refs.moveable.showPopup = false
        this.markerTypeShow = true
        this.markerShow = false
      } else {
        const data = {
          id: id,
          lng: this.$store.state.location.lng,
          lat: this.$store.state.location.lat
        }
        const res = await getPoi(data)

        if (res) {
          this.markerInfo = res.payload.list[0]
          if (this.toilet) {
            this.toilet.forEach(item => {
              if (item.name.indexOf(this.markerInfo.name) !== -1) {
                console.log('包含厕所')
                this.toiletBtn = true
                return
              }
            })
          }
          if (this.tourist) {
            this.tourist.forEach(item => {
              if (item.name.indexOf(this.markerInfo.name) !== -1) {
                console.log('包含游客中心')
                this.touristBtn = true
                return
              }
            })
          }
          if (this.parking) {
            this.parking.forEach(item => {
              if (item.name.indexOf(this.markerInfo.name) !== -1) {
                console.log('包含停车场')
                this.parkingBtn = true
                return
              }
            })
          }
          if (this.AED) {
            this.AED.forEach(item => {
              if (item.name.indexOf(this.markerInfo.name) !== -1) {
                console.log('包含AED')
                this.AEDBtn = true
                return
              }
            })
          }
          if (this.nursery) {
            this.nursery.forEach(item => {
              if (item.name.indexOf(this.markerInfo.name) !== -1) {
                console.log('包含母婴室')
                this.nurseryBtn = true
                return
              }
            })
          }
        }
        this.$refs.navtabs.showPopup = false
        this.$refs.moveable.showPopup = false
        this.markerShow = true
      }
    },
    closeMakerShow(id) {
      if (this.oldMarker) {
        this.oldMarker.setStyle({
          color: 'black'
        })
        this.oldMarker.setZIndex(9)
      }
      if (id === 185 || id === 186 || id === 187) {
        this.$refs.navtabs.showPopup = false
        this.markerTypeShow = false
        this.markerShow = true
        this.getMarkerInfo(this.markerInfo.id)
      } else {
        this.markerShow = false
        this.$refs.navtabs.showPopup = false
        this.markerTypeShow = false
        this.$refs.moveable.showPopup = true
      }
    },
    handleMapAll() {
      if (this.location) {
        this.closeLocation()
      } else {
        this.map.flyTo(this.allCenter, this.allZoom)
      }
    },
    handleLocation() {
      const convertor = new this.BMapGL.Convertor()
      const pointArr = []
      pointArr.push(this.myLocation)
      convertor.translate(pointArr, 1, 5, this.translateCallback)
      this.showLocation = true
    },
    closeLocation() {
      this.map.removeOverlay(this.location)
      this.location = ''
      this.showLocation = false
      this.showLocation3D = false
      this.map.setHeading(0)
      this.map.setTilt(0)
      this.map.flyTo(this.allCenter, this.allZoom)
    },
    updateLocation(position) {
      this.$store.commit('CHANGELOCATION', {
        lng: position.coords.longitude,
        lat: position.coords.latitude
      })
      this.myLocation = new this.BMapGL.Point(position.coords.longitude, position.coords.latitude)
      const myLocation = JSON.stringify(this.myLocation)
      localStorage.setItem('myLocation', myLocation)
      if (this.showLocation) {
        const convertor = new this.BMapGL.Convertor()
        const pointArr = []
        pointArr.push(this.myLocation)
        convertor.translate(pointArr, 1, 5, this.translateCallback)
      }
    },
    translateCallback(data) {
      if (data.status === 0) {
        if (!this.location) {
          const opts = {
            position: data.points[0],
            offset: new this.BMapGL.Size(0, 0)
          }
          this.location = new this.BMapGL.Label('我的位置', opts)
          this.location.setStyle({ ...this.markerLabelStyle,
            background: `url(/static/images/map/icon-my.svg) -5px center no-repeat #fff`
          })
          this.map.addOverlay(this.location)
          this.map.flyTo(data.points[0], 17)
        } else {
          this.location.setPosition(data.points[0])
          this.map.panTo(data.points[0])
        }
      }
    },
    updateErrorStatus(message) {
      this.statusHtml = '<strong>Error</strong>:' + message
    },
    handleLocationError(error) {
      switch (error.code) {
        case 0:
          this.updateErrorStatus('检索您的位置时出错。其他详细信息：' + error.message)
          break
        case 1:
          this.updateErrorStatus('用户选择不共享其位置：' + error.message)
          break
        case 2:
          this.updateErrorStatus('浏览器无法确定您的位置。其他详细信息：' + error.message)
          break
        case 3:
          this.updateErrorStatus('浏览器在检索位置之前超时')
          break
      }
    },
    handleBaiduUrl(marker) {
      window.location.href = `https://api.map.baidu.com/marker?location=${marker.lat},${marker.lng}&title=${marker.name}&content=${marker.name}&output=html&src=webapp.baidu.openAPIdemo`
    },
    handleLocation2D() {
      this.showLocation3D = true
      this.map.setHeading(64.5)
      this.map.setTilt(73)
    },
    handleLocation3D() {
      this.showLocation3D = false
      this.map.setHeading(0)
      this.map.setTilt(0)
    }
  }
}
</script>

<style lang="less" scoped>
@import '~@/styles/public.less';
.startImg{
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  display:flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
}
.startImg img{ width: 100%; height: auto;}
.hideImg{
  opacity: 0;
  margin-top: -100vh;
  transition: all 1s ease-in;
  z-index: -99;
}
.home {
  position: relative;
}
.btn-all{ width: 34px; height: 34px; border: 1px #d9d7d5 solid; position: absolute; right: 10px; top: 70px; z-index: 999; background: #fff; box-shadow: 1px 1px 1px #999; border-radius: 3px; padding: 5px;
  img{ width: 22px; height: 22px;}
}
.btn-list{ width: 34px; height: 34px; border: 1px #d9d7d5 solid; position: absolute; right: 10px; top: 112px; z-index: 999; background: #fff; box-shadow: 1px 1px 1px #999; border-radius: 3px; padding: 5px;
  img{ width: 22px; height: 22px;}
}
.map-btn{ z-index: 998;border-radius: 10px; box-shadow: 0px 10px 20px rgba(120, 121, 147, 0.09);
  .map-line{ width: 25px; height: 1px; background: #e5e5e5; margin: 0 auto;}
  img{ width: 40px; height: auto;}
}
.map-btn-left{ width: 40px; position: fixed; top: 10px; right: 10px; background: #fff; text-align: center; font-size: 12px; line-height: 24px;}
.map-btn-right{ max-width:80px; position: fixed; top: 10px; right: 10px; text-align: right; font-size: 12px; padding: 5px 10px; line-height: 18px;  background: rgba(255, 255, 255, 0.8); }
.map-btn-bottom{ width: 36px; height: 36px; border-radius: 40px; position: fixed; bottom: 180px; left: 10px; background: #fff; text-align: center; font-size: 14px; line-height: 36px; font-weight: bolder; color: #3165ec;}

.map {
  width: 100%;
  height: 100vh;
  padding-bottom: 0;
  box-sizing: border-box;
  z-index: 900 !important;
  /deep/.anchorBL {
    display: none;
    visibility: hidden;
  }
  // /deep/.BMap_Marker {
  //   pointer-events: none;
  // }
  /deep/.BMap_zlHolder {
    display: none;
    visibility: hidden;
  }
}
.info { width: 5rem; max-width: 250px;
  h2 {
    border-bottom: 1px solid #eee;
    font-size: 16px;
    display: flex;
    span { margin-left: 15px; font-size: 12px; }
    img{ width: 16px; height: 16px; }
  }
  .info-all {
    height: auto !important;
    min-height: 10px;
    height: 10px;
    overflow: hidden;
    _overflow: visible;
    padding: 5px 0;
    display: flex;
    .info-img {
      width: 1.8rem;
      max-width: 100px;
      float: left;
      img {
        width: 1.8rem;
        max-width: 100px;
        height: auto;
      }
    }
    .info-text {
      width: 100%;
      max-width: 140px;
      margin-left: 10px;
      p {
        font-size: 12px;
        width: 100%;
        line-height: 20px;
        max-height: 80px;
        overflow-y: auto;
      }
    }
  }
}

// .marker-window{
// 	width: 96%;
//   padding: 0.2rem;
// 	background: #fff;
// 	position: fixed;
// 	left:2%;
// 	bottom: 60px;
// 	z-index: 998;
//   box-shadow: 0 0 10px rgba(194, 194, 194, 0.5);
//   border-radius: 5px;
//   .marker-close{ text-align: center; position: absolute; right: -0.15rem; top:-0.2rem;
//     img{ width: 0.5rem; height: auto; }
//   }
//   .marker-top{ width: 100%; padding: 0.15rem;  display: flex;
//     .marker-tit{ width: 100%; font-size: 0.28rem; line-height: 0.5rem;
//       img{ width: 0.4rem; height: auto; }
//      }
//   }
//   .marker-bottom{ width: 100%; border-top: 1px rgba(0, 0, 0, 0.03) solid;
//     .marker-bottom-l{ float: left; margin-top: 0.2rem; margin-right: 0.1rem;
//       span{ font-size: 0.24rem; line-height: 0.2rem; border:1px rgba(111, 133, 255, 1) solid; background: rgba(111, 133, 255, 0.8); border-radius: 0.4rem; padding: 0.05rem 0.15rem; color: #fff; }
//     }
//     .marker-bottom-ls{ float: left; margin-top: 0.2rem; margin-right: 0.1rem;
//       span{ font-size: 0.24rem; line-height: 0.2rem; border:1px #666 solid;  border-radius: 0.4rem; padding: 0.05rem 0.15rem; color: #666; }
//     }
//     .marker-bottom-r{ width: 100%;
//       span{  font-size: 0.24rem; line-height: 0.3rem; padding-top:0.1rem; }
//       .marker-order{ color: rgba(255, 69, 69, 1); float: right;
//         img{ width:auto; height: 0.3rem; margin-right: 0.1rem; }
//       }

//       .marker-show{  color: #333; float: right; margin-left: 0.2rem;
//         img{ width: auto; height: 0.3rem; margin-right: 0.1rem;}
//       }
//     }
//   }
//   .marker-go{  color: rgba(111, 133, 255, 1); float: right; margin-right: 0.2rem; font-size: 0.26rem; line-height: 0.3rem;  padding-top:0.1rem;
//     img{ width: auto; height: 0.3rem; margin-right: 0.1rem;}
//   }
// }
// .marker-index{
//   z-index: 999;
// }
// .listHot{
//   width: 100%;
//   height :1.5rem;
//   .autoHeight();
//   padding-bottom: 0.15rem;
//   background: @white;
//   display: flex;

// }
// .list-img{
//   div{ position: relative;
//     img{
//       width :2rem;
//       height :1.4rem;
//       border-radius: 8px;
//     }
//   }
//   span{ width: 100%; position: absolute; left:0; bottom: 0; text-align: center; font-size: 0.2rem; line-height: 0.3rem; padding: 0.05rem 0; color: #fff; background: rgba(0, 0, 0, 0.6); border-bottom-right-radius: 8px; border-bottom-left-radius: 8px; }
// }
// .list-txt{
//   width: 100%;
//   float :right;
//   .autoHeight();
//   position : relative;
//   padding-left: 0.15rem;
//   padding-bottom: 0.1rem;
//   img{ width: 0.3rem; height: 0.3rem;}
//   p{  width: 85%;
//     font-size :0.24rem;
//     line-height : 0.4rem;
//     .LimitWord();
//     -webkit-line-clamp :2;
//     color :#666;
//   }
//   h2{ width: 100%; display: flex; font-size: 0.3rem; line-height: 0.5rem;
//     a{ color: #333;}
//   }
//   h4{ height: 0.3rem;
//     span{ font-size: 0.3rem; line-height: 0.3rem; color: #FFA717; margin-right: 0.1rem; font-family: Arial;}
//   }
//   h5{  font-size: 0.24rem; color: #666; line-height: 0.44rem;
//     b{ color: #333; font-weight:normal;}
//   }
// }

.marker-window{
	width: 96%;
  padding: 10px;
	background: #fff;
	position: fixed;
	left:2%;
	bottom: 60px;
	z-index: 998;
  box-shadow: 0 0 10px rgba(194, 194, 194, 0.5);
  border-radius: 5px;
  .marker-close{ text-align: center; position: absolute; right: -2%; top:-20px;
    img{ width: 40px; height: auto; }
  }
  .marker-top{ width: 100%; padding: 15px;  display: flex;
    .marker-tit{ width: 100%; font-size: 28px; line-height: 50px;
      img{ width: 40px; height: auto; }
     }
  }
  .marker-bottom{ width: 100%; border-top: 1px rgba(0, 0, 0, 0.03) solid;
    .marker-bottom-l{ float: left; margin-top:8px; margin-right: 8px;
      span{ font-size: 14px; line-height: 30px; border:1px rgba(111, 133, 255, 1) solid; background: rgba(111, 133, 255, 0.8); border-radius: 40px; padding: 4px 12px; color: #fff; }
    }
    .marker-bottom-ls{ float: left; margin-top:8px; margin-right: 8px;
      span{ font-size: 14px; line-height: 30px; border:1px #666 solid;  border-radius: 40px; padding: 4px 12px; color: #666; }
    }
    .marker-bottom-r{ width: 100%;
      span{  font-size: 14px; line-height: 34px; padding-top:10px; }
      .marker-order{ color: rgba(255, 69, 69, 1); float: right;
        img{ width:auto; height: 30px; margin-right:10px; }
      }
      .marker-show{  color: #333; float: right; margin-left:20px;
        img{ width: auto; height: 30px; margin-right:10px;}
      }
    }
  }
  .marker-go{  color: rgba(111, 133, 255, 1); float: right; font-size: 16px; line-height:20px;  padding-top:10px;
    img{ width: auto; height:20px; margin-right: 10px;}
  }
}
.marker-index{
  z-index: 999;
}
.listHot{
  width: 100%;
  height :150px;
  .autoHeight();
  padding-bottom: 10px;
  background: @white;
  display: flex;
}
.list-img{
  div{ position: relative;
    img{
      width :130px;
      height :auto;
      border-radius: 8px;
    }
  }
  span{ width: 100%; position: absolute; left:0; bottom: 0; text-align: center; font-size: 14px; line-height: 20px; padding: 5px 0; color: #fff; background: rgba(0, 0, 0, 0.6); border-bottom-right-radius: 8px; border-bottom-left-radius: 8px; }
}
.list-txt{
  width: 100%;
  float :right;
  .autoHeight();
  position : relative;
  padding:0 15px;
  box-sizing: border-box;
  img{ width: 30px; height: 30px;}
  p{  width: 85%;
    font-size :14px;
    line-height : 20px;
    .LimitWord();
    -webkit-line-clamp :2;
    color :#666;
  }
  h2{ width: 100%; display: flex; font-size: 18px; line-height: 30px;
    a{ color: #333;}
  }
  h4{ height: 30px;
    span{ font-size: 14px; line-height: 24px; color: #FFA717; margin-right: 10px; font-family: Arial;}
  }
  h5{  font-size: 14px; color: #666; line-height: 24px;
    b{ color: #333; font-weight:normal;}
  }
}

</style>
