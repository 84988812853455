<template>
  <div>
    <div class="tabbar">
      <ul>
        <li v-for="(item,index) in columns" :key="item.id" :class="active===index?'actived':''" @click="handleColumn(item.id,item.name,index )">
          <span :class="(index===(columns.length-1)?'bnone':'')">{{ item.name }}</span>
          <!-- <span>{{ item.name }}</span> -->
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { Tabbar } from 'vant'
import eventBus from '@/utils/eventBus'

export default {
  name: 'Tabbar',
  components: {
    [Tabbar.name]: Tabbar
  },
  props: {
    columns: { type: Array, default: () => [] }
  },
  data() {
    return {
      active: 0,
      iframeUrl: '',
      columnId: ''
    }
  },
  methods: {
    handleColumn(id, name, index) {
      this.active = index
      this.$emit('closeMakerShow')
      if (name === '手绘地图') {
        this.iframeUrl = this.columns[index].drawing_map
        eventBus.$emit('showIframeUrl', this.iframeUrl)
      } else {
        eventBus.$emit('columnId', id)
        this.columns.forEach(column => {
          if (column.id === id && column.children) {
            const columnsTwo = column.children[0]
            if (columnsTwo.children) {
              this.columnId = columnsTwo.children[0].id
              this.$emit('changeColumnId', this.columnId)
            } else {
              this.$emit('changeColumnId', columnsTwo.id)
            }
          }
        })
      }
    }
  }
}
</script>

<style lang="less" scoped>
.tabbar {
  width: 100vw;
  height: 50px;
  position: fixed;
  bottom: 0;
  left: 0;
  display: flex;
  border-top: 1px #ccc solid;
  box-shadow: 0 -5px 10px rgba(255, 255, 255, 0.37);
  z-index: 2000;
  background: #fff;
}
.tabbar li {
  width: 33vw;
  height: 50px;
  padding-top: 15px;
  float: left;
  text-align: center;
  font-size: 13px;
  color: #999;
}
.tabbar .actived{
  color:#333
}
.tabbar span {
  height:20px;
  display: block;
  line-height: 20px;
  border-right: 1px solid #ccc;
}
.tabbar span.bnone {
  border: none;
}
</style>
